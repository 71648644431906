<template>
    <div :id="id" ref="gridContainer" :class="{ 'border d-flex flex-column': !isMobile, 'border-top-0': hideTop && !hideBottom, 'border-bottom-0': hideBottom, 'd-flex flex-column': isMobile }" :style="{ 'min-width': minWidth, 'max-width': props.maxWidth  }">
        <div class="d-flex justify-content-between bg-primary-subtle ps-2 pb-2 flex-wrap" v-if="!hideTop">
            <div class="pt-2" >
                <input
                    ref="searchBox"
                    type="search"
                    class="form-control form-control-sm"
                    style="box-shadow: none!important; border-radius: 0px!important; transition: width 0.3s ease"
                    :style="{ width: searchBoxWidth }"
                    v-on="{ input: filterForProperties, focus: searchBoxFocus, blur: searchBoxFocus }"
                    :placeholder='$t("Search...")'
                /> 
            </div>
            <div class="pt-2 hstack" ><slot name="actionButtons"> </slot> <slot name="headerButtons"></slot></div>
                

            <div class="d-flex gap-3 pt-2">
                <div class="form-check form-switch pt-1 pe-1 me-2" >
                    <input class="form-check-input" type="checkbox" role="switch" @change="hideBlanksFnc()" v-model="withValues" id="withValueSwitch">
                    <label class="form-check-label" for="withValueSwitch">{{ $t("Hide blanks") }}</label>
                </div>
            </div>
        </div>
        <template v-if="isMobile">
            <div class="d-flex flex-column gap-2" :class="{ 'mt-2': !hideTop, 'overflow-y-auto': scrollable }">
                <slot />
            </div>
        </template>
        <template v-else>
            <div :class="{ 'overflow-y-auto': scrollable }">
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted, onBeforeUnmount, provide } from 'vue';
    import { InjectionKeys } from 'o365-utils';
    import { addEventListener } from 'o365-vue-utils';
    import { MediaQueryManager } from 'o365-ui-components';
    import { isMobile } from "o365.GlobalState.ts";
    import { localStorageHelper as localStorage } from 'o365-modules';

    const isScreenXs = ref(MediaQueryManager.getQueryMatchState('xs'));

    const searchBox = ref(null);
    const isSearchFocused = ref(false);
    let debounce = null;

    const props = defineProps({
        id: {
            type: String,
            default: (_) => `properties_grid_${crypto.randomUUID()}`
        },
        visibleFieldsObject:Object,
        dataObject: Object,
        hideOkCancel: {
            default:false,
            type: Boolean,
        },
        hideGroupChevron: {
            default:false,
            type: Boolean,
        },
        hideTop: {
            default:false,
            type: Boolean,
        },
        hideBottom: {
            default: false,
            type: Boolean
        },
        hideBlanks: {
            default: false,
            type: Boolean
        },
        disableAutosave: {
            default :false,
            type: Boolean,
        },
        maxWidth: {
            default: "",
            type: String
        },
        scrollable: {
            type: Boolean,
            required: false,
        },
    });

    const minWidth = ref(isMobile.value ? '' : '400px');

    const gridContainer = ref();
    const propFilter = ref(null);
    const storageKey = '_props_withValueSwitch_'+(props.dataObject?props.dataObject.id:"");
    const vLocalwithValues = localStorage.getItem(storageKey, {global:false}, props.hideBlanks);
    const withValues = ref(typeof vLocalwithValues == "string" ? JSON.parse(vLocalwithValues) : vLocalwithValues);
    const activeItemSwitch = ref(null);

    const sharedObj = ref({
        get hideGroupChevron() { return props.hideGroupChevron; },
        visibleFieldsObject: props.visibleFieldsObject,
        propFilter,
        withValues,
        dataObject: props.dataObject,
        activeItemSwitch,
        groups: [],
        usePropertiesGroup() {
            const id = crypto.randomUUID();
            const group = { id: id, index: this.groups.length };

            // onMounted(() => {
                this.groups.push(group);
            // });

            // onBeforeUnmount(() => {
                // this.groups = this.groups.filter(e => e.id === id);
            // });

            return group;
        },
    });
    provide(InjectionKeys.propertiesGridKey, sharedObj);

    const hideBlanksFnc = () =>{
        localStorage.setItem(storageKey,withValues.value,{global:false})
    }

    const searchBoxWidth = computed(() => {
        if (isScreenXs.value) {
            return isSearchFocused.value ? '120px' : '80px';
        }
        
        return isSearchFocused.value ? '220px' : '120px';
    });

    const searchBoxFocus = (e) => {
        isSearchFocused.value = e.type === 'focus';
    }

    function filterForProperties(event){
        var value = event.target.value;
        propFilter.value = value;
    }

    addEventListener(window.document.body, 'mouseup', (pEvent) => {
        if (props.disableAutosave) { return; }
        const target = pEvent.target;
        if (gridContainer.value == null || gridContainer.value.contains(target)) { return; }
        if(target.closest('[data-grid-skip-click-handler]')) return;
        
        if (props.dataObject && props.dataObject.storage.hasChanges) {
         
            props.dataObject.save();
            if(target.nodeName == "BUTTON" ){target.click();}
        }
        
    });

    onMounted(() => {
        MediaQueryManager.on('QueryChanged', () => {
            isScreenXs.value = MediaQueryManager.getQueryMatchState('xs');
        });
    });

e
</script>